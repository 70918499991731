import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

export default makeStyles({
  paper: theme.mixins.gutters({
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(0.25),
  }),
  star: {
    fontSize: theme.typography.fontSize * 2,
  },
  startAvatar: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize * 2,
  },
  bronzeAvatar: {
    color: '#cd7f32',
    fontSize: theme.typography.fontSize * 2,
  },
  silverAvatar: {
    color: '#bec2cb',
    fontSize: theme.typography.fontSize * 2,
  },
  goldAvatar: {
    color: '#D4AF37',
    fontSize: theme.typography.fontSize * 2,
  },
  nomargin: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    margin: 0,
    padding: 0,
  },
  center: {
    maxWidth: '100%',
  },
  card: {
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    cursor: 'pointer',
  },
  landingPricesWrapper: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  activeCardContainer: {
    color: theme.palette.grey[200],
  },
  cardContainer: {
    color: theme.palette.grey[600],
  },
  pointer: {
    cursor: 'pointer',
  },
  infoIcon: {
    color: theme.palette.secondary.light,
  },
});
