import React from 'react';
import { string, func } from 'prop-types';
import loadable from '@loadable/component';

import { Grid, Paper } from '@material-ui/core';
import useStyles from './styles';

const StartUp = loadable(() => import('components/landing-prices/select/start-up'));
const Bronze = loadable(() => import('components/landing-prices/select/bronze'));
const Silver = loadable(() => import('components/landing-prices/select/silver'));
const Gold = loadable(() => import('components/landing-prices/select/gold'));

const WrappedLandingPricesSelect = ({ subscription = '', onSelect = () => {} }) => {
  const styles = useStyles();
  return (
      <Paper className={styles.paper} elevation={4}>
        <Grid spacing={1}
            container
            direction="row"
            className={styles.landingPricesWrapper}
         >
         <Grid item lg={3} md={6} xs={12}>
           <StartUp onClick={onSelect} sub={subscription}/>
         </Grid>
         <Grid item lg={3} md={6} xs={12}>
           <Bronze onClick={onSelect} sub={subscription}/>
         </Grid>
         <Grid item lg={3} md={6} xs={12}>
           <Silver onClick={onSelect} sub={subscription}/>
         </Grid>
         <Grid item lg={3} md={6} xs={12}>
           <Gold onClick={onSelect} sub={subscription}/>
         </Grid>
        </Grid>
      </Paper>
  );
};

WrappedLandingPricesSelect.propTypes = { subscription: string, onSelect: func };

export default WrappedLandingPricesSelect;
